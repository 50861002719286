<template>
    <v-dialog :persistent="deleting" v-model="deleteDialog" max-width="500">
        <template v-slot:activator="{ on }">
            <v-btn block class="white--text" color="primary" text v-on="on" :loading="deleting" :disabled="selected.length === 0">
                <div style="width:100%" class="d-flex align-center justify-start">
                    <v-icon class="mr-1" small>delete</v-icon>
                    delete
                </div>
            </v-btn>
        </template>
        <v-card>
            <v-card-title>
                <div class="title lighten-2 primary--text">
                    <v-icon color="primary" class="mr-1">delete</v-icon>
                    <span v-if="objectType === objectTypes.FILES" class="text-uppercase">file deletion</span>
                    <span v-if="objectType === objectTypes.TABLES" class="text-uppercase">table deletion</span>
                    <span v-if="objectType === objectTypes.COLUMNS" class="text-uppercase">column deletion</span>
                </div>
            </v-card-title>
            <v-divider class="mb-1"></v-divider>
            <v-card-text>
                <p>Are you sure you want to delete the following {{ objectType }}:</p>
                <ul>
                    <li v-for="(item, index) in selected" :key="index">{{ item.short_id }}</li>
                </ul>
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn :disabled="deleting" color="primary" class="mr-1" text @click="deleteDialog = false">Cancel</v-btn>
                <v-btn v-if="objectType === objectTypes.FILES" :loading="deleting" text color="error" @click="deleteFiles">Delete</v-btn>
                <v-btn v-else :loading="deleting" text color="error" @click="deleteTablesAndColumns">Delete</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>
<script>
import { mapState } from 'vuex'

export default {
    name: 'DeleteButton',
    data: function() {
        return {
            deleteDialog: false,
            remainingObjectsToDelete: 0,
            deleting: false,
            objectTypes: {
                FILES: 'files',
                TABLES: 'tables',
                COLUMNS: 'columns'
            }
        }
    },
    props: {
        selected: Array,
        objectType: String,
        dispatchFunction: Function
    },
    methods: {
        removeFromStaging() {
            this.$props.selected.forEach(item => {
                if (this.$props.objectType === this.$data.objectTypes.FILES) {
                    if (this.stageFilesIds.includes(item.fid)) {
                        this.$store.dispatch('snapshotStore/updateStagingObjects', {
                            itemType: 'file',
                            newItems: [item],
                            updateMode: 'remove'
                        })
                    }
                }
                if (this.$props.objectType === this.$data.objectTypes.TABLES) {
                    if (this.stageTableIds.includes(item.tid)) {
                        this.$store.dispatch('snapshotStore/updateStagingObjects', {
                            itemType: 'table',
                            newItems: [item],
                            updateMode: 'remove'
                        })
                    }
                }
            })
        },
        deleteTablesAndColumns() {
            this.$data.deleting = true
            this.removeFromStaging()
            this.$data.remainingObjectsToDelete = this.$props.selected.length
            this.$props.selected.forEach(item => {
                const apiURL = this.$props.objectType === this.$data.objectTypes.TABLES ? `tables/${item.tid}` : `columns/${item.cid}`
                this.$axios
                    .delete(apiURL)
                    .then(() => {
                        this.$store.dispatch('showSnackBar', {
                            snackBarText: item.short_id + ' was deleted successfully.',
                            snackBarTimeout: 5000,
                            snackBarIcon: 'check_circle'
                        })
                    })
                    .catch(() => {
                        this.$store.dispatch('showSnackBar', {
                            snackBarText: 'FAILED to delete: ' + item.short_id,
                            snackBarTimeout: 10000,
                            snackBarIcon: 'error'
                        })
                    })
                    .finally(() => {
                        this.$data.remainingObjectsToDelete -= 1
                        if (this.$data.remainingObjectsToDelete === 0) {
                            this.$data.deleting = false
                            this.$emit('finished', { value: true })
                            this.dispatchFunction()
                        }
                    })
            })
            this.$data.deleteDialog = false
        },
        deleteFiles() {
            this.$data.deleting = true
            this.removeFromStaging()
            const fids = this.$props.selected.map(file => file.fid)
            const apiURL = '/files/batch_delete_async'
            const postBody = { fids: fids }
            const userTasksRoute = this.$router.resolve({ name: 'user-tasks' }).href
            this.$axios
                .post(apiURL, postBody)
                .then(() => {
                    this.$store.dispatch('showSnackBar', {
                        snackBarText: `Files succesfully submitted for deletion, you can track progress <a href="${userTasksRoute}">here</a>`,
                        snackBarTimeout: 10000,
                        snackBarIcon: 'check_circle'
                    })
                    this.$store.dispatch('userStore/fetchUserTasks')
                })
                .catch(() => {
                    this.$store.dispatch('showSnackBar', {
                        snackBarText: 'Failed to delete the selected files',
                        snackBarTimeout: 10000,
                        snackBarIcon: 'error'
                    })
                })
                .finally(() => {
                    this.dispatchFunction()
                    this.$emit('finished', { value: true })
                    this.$data.deleting = false
                    this.$data.deleteDialog = false
                })
        }
    },
    computed: {
        ...mapState('snapshotStore', ['stagingObjects']),
        stageFilesIds() {
            return this.stagingObjects.files.map(file => file.fid)
        },
        stageTableIds() {
            return this.stagingObjects.tables.map(table => table.tid)
        }
    }
}
</script>
