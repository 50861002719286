var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    {
      attrs: { persistent: _vm.deleting, "max-width": "500" },
      scopedSlots: _vm._u([
        {
          key: "activator",
          fn: function(ref) {
            var on = ref.on
            return [
              _c(
                "v-btn",
                _vm._g(
                  {
                    staticClass: "white--text",
                    attrs: {
                      block: "",
                      color: "primary",
                      text: "",
                      loading: _vm.deleting,
                      disabled: _vm.selected.length === 0
                    }
                  },
                  on
                ),
                [
                  _c(
                    "div",
                    {
                      staticClass: "d-flex align-center justify-start",
                      staticStyle: { width: "100%" }
                    },
                    [
                      _c(
                        "v-icon",
                        { staticClass: "mr-1", attrs: { small: "" } },
                        [_vm._v("delete")]
                      ),
                      _vm._v(" delete ")
                    ],
                    1
                  )
                ]
              )
            ]
          }
        }
      ]),
      model: {
        value: _vm.deleteDialog,
        callback: function($$v) {
          _vm.deleteDialog = $$v
        },
        expression: "deleteDialog"
      }
    },
    [
      _c(
        "v-card",
        [
          _c("v-card-title", [
            _c(
              "div",
              { staticClass: "title lighten-2 primary--text" },
              [
                _c(
                  "v-icon",
                  { staticClass: "mr-1", attrs: { color: "primary" } },
                  [_vm._v("delete")]
                ),
                _vm.objectType === _vm.objectTypes.FILES
                  ? _c("span", { staticClass: "text-uppercase" }, [
                      _vm._v("file deletion")
                    ])
                  : _vm._e(),
                _vm.objectType === _vm.objectTypes.TABLES
                  ? _c("span", { staticClass: "text-uppercase" }, [
                      _vm._v("table deletion")
                    ])
                  : _vm._e(),
                _vm.objectType === _vm.objectTypes.COLUMNS
                  ? _c("span", { staticClass: "text-uppercase" }, [
                      _vm._v("column deletion")
                    ])
                  : _vm._e()
              ],
              1
            )
          ]),
          _c("v-divider", { staticClass: "mb-1" }),
          _c("v-card-text", [
            _c("p", [
              _vm._v(
                "Are you sure you want to delete the following " +
                  _vm._s(_vm.objectType) +
                  ":"
              )
            ]),
            _c(
              "ul",
              _vm._l(_vm.selected, function(item, index) {
                return _c("li", { key: index }, [_vm._v(_vm._s(item.short_id))])
              }),
              0
            )
          ]),
          _c(
            "v-card-actions",
            [
              _c("v-spacer"),
              _c(
                "v-btn",
                {
                  staticClass: "mr-1",
                  attrs: { disabled: _vm.deleting, color: "primary", text: "" },
                  on: {
                    click: function($event) {
                      _vm.deleteDialog = false
                    }
                  }
                },
                [_vm._v("Cancel")]
              ),
              _vm.objectType === _vm.objectTypes.FILES
                ? _c(
                    "v-btn",
                    {
                      attrs: {
                        loading: _vm.deleting,
                        text: "",
                        color: "error"
                      },
                      on: { click: _vm.deleteFiles }
                    },
                    [_vm._v("Delete")]
                  )
                : _c(
                    "v-btn",
                    {
                      attrs: {
                        loading: _vm.deleting,
                        text: "",
                        color: "error"
                      },
                      on: { click: _vm.deleteTablesAndColumns }
                    },
                    [_vm._v("Delete")]
                  )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }